import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button } from '@mui/material';
import SearchBar from './search';
import {
  createFamilyMemberProfile,
  updateFamilyMemberProfile,
  updateIndependentFamilyMemberProfile,
} from '../../../actions/matrimonialAction';
import { getLoggedInUserId } from '../../../actions/authAction';
import LoadingSpinner from '../../loader';
import { toast } from 'react-toastify';
import { AppContext } from '../Context';
import AddIndependentUser from './addIndependentUser';
import FormatDisplayName, { ExtractDataFromProfile } from './formatDisplayName';
import data from '../../../constantData.json';

const AddMemberForm = ({ onClose, editMemberProfile, matrimonialId, editMemberMode }) => {
  const [graphNodeId, setGraphNodeId] = useState('');
  const [relation, setRelation] = useState('');
  const [occupation, setOccupation] = useState('');
  const [qualification, setQualification] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [relationError, setRelationError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [showIndependentUserForm, setShowIndependentUserForm] = useState(false);
  const [independentUserData, setIndependentUserData] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [editMemberData, setEditMemberData] = useState({});
  const [displayName, setDisplayName] = useState('');

  const loggedInUserId = getLoggedInUserId();
  const { handleChangeMultiValues, formValues } = useContext(AppContext);
  const { graph_node_id } = formValues;
  console.log('graph node id ', graph_node_id);
  const relationOption = data.RELATION_OPTIONS.values;

  useEffect(() => {
    if (editMemberMode) {
      const { name, gautra, subcaste, village } = ExtractDataFromProfile(editMemberProfile);
      setEditMemberData({ name, gautra, subcaste, village });
      setIndependentUserData(editMemberProfile?.independent_profile_data);
      setGraphNodeId(editMemberProfile?.graph_node_id || '');
      const matchingProfile = editMemberProfile?.matrimonial_id_data?.find(
        (profile) => profile?.matrimonial_id === matrimonialId,
      );
      setRelation(matchingProfile?.relation || '');
      setOccupation(editMemberProfile?.occupation || '');
      setQualification(editMemberProfile?.qualification || '');
      if (editMemberProfile.contact_number && editMemberProfile.contact_number.length > 0) {
        setContactNumber(editMemberProfile.contact_number[0]);
      }
    }
  }, [editMemberProfile]);

  const handleSave = async () => {
    if (!graphNodeId) {
      if (!independentUserData.name) {
        setNameError(true);
        return;
      }
    }
    if (!relation) {
      setRelationError(true);
      return;
    }
    const createData = () => {
      const data = {
        created_by: loggedInUserId,
        matrimonial_id_data: [{ matrimonial_id: matrimonialId, relation: relation }],
        occupation,
        qualification,
        contact_number: [contactNumber],
      };
      if (graphNodeId) {
        data.graph_node_id = graphNodeId?.trim();
      } else if (independentUserData.name) {
        // data.independent_profile_data = independentUserData.;
        data.independent_profile_data = {
          name: independentUserData?.name || '',
          father_name: independentUserData?.father_name || '',
          village: independentUserData?.village || '',
          gautra: independentUserData?.gautra || '',
          subcaste: independentUserData?.subcaste || '',
        };
      }
      return data;
    };
    const data = createData();

    try {
      setLoading(true);
      let result;
      if (editMemberMode) {
        if (editMemberProfile?.graph_node_id && graphNodeId) {
          result = await updateFamilyMemberProfile(matrimonialId, graphNodeId, data);
          console.log('Result from updateFamilyMemberProfile', result);
        } else if (editMemberProfile?.independent_profile_data) {
          result = await updateIndependentFamilyMemberProfile(editMemberProfile?._id, data);
          console.log('Result from updateIndependentFamilyMemberProfile', result);
        }
      } else {
        result = await createFamilyMemberProfile(data);
        console.log('Result from createFamilyMemberProfile', result);
      }
      if (result?.status) {
        if (editMemberMode) {
          toast.success('Family Member Updated Successfully');
        } else {
          toast.success('Family Member Added Successfully');
        }
      }

      setLoading(false);
      onClose();
    } catch (error) {
      console.error('Error updating or creating family member profile', error);
      toast.error(`An error occurred while updating or creating the family member profile., ${error}`);
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '0 auto' }}>
      {loading ? (
        <LoadingSpinner message="Adding Family Mmember" />
      ) : (
        <>
          {nameError && <p style={{ color: 'red', marginTop: '5px' }}>Name is required</p>}{' '}
          {graph_node_id?.value ? (
            <SearchBar
              editMemberData={editMemberData}
              setSelectedUser={(userData) => {
                if (userData) {
                  setGraphNodeId(userData.uid);
                  setNameError(false);
                }
              }}
              handleNoneOfTheseClick={(searchParams) => {
                setShowIndependentUserForm(true);
                setSearchParams(searchParams);
              }}
              disable={editMemberMode}
            />
          ) : (
            <TextField
              label="Add Independent User"
              fullWidth
              value={displayName || ''}
              onClick={() => setShowIndependentUserForm(true)}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          {showIndependentUserForm && (
            <AddIndependentUser
              onClose={() => setShowIndependentUserForm(false)}
              showPhoneNoField={false}
              handleCreate={(data) => {
                console.log('data ', data);
                const memberName = FormatDisplayName(data?.name, data?.gautra, data?.subcaste);
                setDisplayName(memberName);
                setEditMemberData(data);
                setIndependentUserData(data);
                setShowIndependentUserForm(false); // Close the form when creating
              }}
              searchParams={searchParams}
            />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            select
            // label="Relation"
            SelectProps={{
              native: true,
            }}
            name="relation"
            value={relation} // This should be your state value
            onChange={(e) => setRelation(e.target.value)} // Update state on change
            error={relationError} // Show error if relationError is true
            helperText={relationError ? 'Please select a relation' : ''} // Helper text for errors
            required
          >
            <option value="">Select Relation</option> {/* Default option with empty value */}
            {relationOption?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.display}
              </option>
            ))}
          </TextField>
          <TextField label="Occupation" fullWidth value={occupation} onChange={(e) => setOccupation(e.target.value)} />
          <TextField
            label="Qualification"
            fullWidth
            value={qualification}
            onChange={(e) => setQualification(e.target.value)}
          />
          <TextField
            label="Contact Number"
            fullWidth
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
          />
        </>
      )}
      <div>
        <Button
          variant="contained"
          sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            borderRadius: '100px',
            marginTop: '10px',
            marginRight: '10px', // Add some margin to separate buttons
          }}
          onClick={handleSave} // Call handleSave function when Save button is clicked
        >
          {editMemberMode ? 'Update' : 'Save'}
        </Button>
        <Button
          variant="contained"
          color="error" // Change button color to red for cancel button
          sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            borderRadius: '100px',
            marginTop: '10px',
          }}
          onClick={onClose} // Call onClose function from props when Cancel button is clicked
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddMemberForm;
